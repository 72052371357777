import React from "react";
import { Body } from "../../components/Body";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

const NotFoundPage = () => (
  <>
    <Header />
    <Body>
      <h2>Page not found</h2>
      <p>
        We're sorry, the page you requested could not be found. Please go back
        to the homepage.
      </p>
    </Body>
    <Footer />
  </>
);

export default NotFoundPage;
